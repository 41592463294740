import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container, Grid, Typography, Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormContainer from './containers/FormContainer';
import SuccessPage from './components/SuccessPage'; // Make sure to create this component

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Container maxWidth='lg' style={{ paddingBottom: '24px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '48px',
            }}
          >
            <a
              href='https://www.blackshark.ai'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <center>
                <img src={`${process.env.PUBLIC_URL}/BSK_logo_neo_dark_row.png`} alt="blackshark.ai ORCA HUNTR" style={{ maxWidth: '80%', height: 'auto' }} />
              </center>
            </a>
            <Typography variant='h4' style={{ marginTop: '20px' }}>
              Welcome to ORCA ™HUNTR
            </Typography>
            <Typography variant='subtitle1' style={{ marginTop: '10px' }}>
              blackshark.ai ORCA ™HUNTR is an object detection AI/ML insights platform that enables no-code prototyping, training, and execution of AI/ML algorithms by anyone - no experience necessary.<br />
              A basic environment includes 1 user, 1 on-demand GPU, and 100 GB of storage. If you need less latency in starting the training process, please select 'always-on' GPU, (additional costs apply).<br />
              Please complete the subscription form below to receive your quote:<br />
            </Typography>
          </div>

          <Grid container spacing={3} style={{ marginTop: '20px', justifyContent: 'center' }}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: '20px' }}>
                <Routes>
                  <Route path='/' element={<FormContainer />} />
                  <Route path='/success' element={<SuccessPage />} />
                </Routes>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
