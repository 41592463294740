// EmailData.js

export class EmailData {
    constructor(subscriptionDuration, numUsers, additionalStorage, alwaysOnGpu, firstName, surname, email, companyName, streetAddress1, streetAddress2, city, country, postalCode, isConsentGiven) {
        this.subscription_duration = subscriptionDuration; // Python: subscription_duration
        this.num_users = numUsers; // Python: num_users
        this.additional_storage = additionalStorage; // Python: additional_storage
        this.always_on_gpu = alwaysOnGpu; // Python: always_on_gpu
        this.first_name = firstName; // Python: first_name
        this.surname = surname; // Python: surname
        this.email = email; // Python: email
        this.company_name = companyName; // Python: company_name
        this.street_address1 = streetAddress1; // Python: street_address1
        this.street_address2 = streetAddress2 || ""; // Python: street_address2
        this.city = city; // Python: city
        this.country = country; // Python: country
        this.postal_code = postalCode || ""; // Python: postal_code
        this.is_consent_given = isConsentGiven; // Python: is_consent_given
    }
}