import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button
} from '@mui/material';

const ReviewOfferDialog = ({
    open,
    onClose,
    onProceed,
    subscriptionDuration,
    numUsers,
    additionalStorage,
    alwaysOnGPU,
    streetAddress1,
    streetAddress2,
    city,
    country,
    postalCode,
    firstName,
    surname,
    isLoading
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Review Your Information</DialogTitle>
          <DialogContent>
            <DialogContentText>Please review your selections before proceeding:</DialogContentText>
            <Typography gutterBottom>Subscription Duration: {subscriptionDuration}</Typography>
            <Typography gutterBottom>First Name: {firstName}</Typography>
            <Typography gutterBottom>Surname: {surname}</Typography>
            <Typography gutterBottom>Number of Users: {numUsers}</Typography>
            <Typography gutterBottom>Additional Storage: {additionalStorage} GB</Typography>
            <Typography gutterBottom>Always-On GPU: {alwaysOnGPU ? 'Yes' : 'No'}</Typography>
            <Typography gutterBottom>Street Address 1: {streetAddress1}</Typography>
            {streetAddress2 && <Typography gutterBottom>Street Address 2: {streetAddress2}</Typography>}
            <Typography gutterBottom>City: {city}</Typography>
            <Typography gutterBottom>Country: {country}</Typography>
            <Typography gutterBottom>Postal Code: {postalCode}</Typography>
            <DialogContentText>
            By clicking 'Proceed' your quote details will be shared with blackshark.ai. We will get back to you within 24 hours with a sales quote. Thank you for considering blackshark.ai
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={onProceed}
              color='primary'
              autoFocus
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Proceed'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    };

export default ReviewOfferDialog;
