import React, { useState} from 'react'
import {
  Typography,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Link,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import sendEmail from '../services/emailServices';
import ReviewOfferDialog from './ReviewOfferDialog';
import useConfig from '../hooks/useConfig';
import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { EmailData } from '../models/EmailData';
import Snackbar from '@mui/material/Snackbar';

const FormContainer = () => {

  const config = useConfig();

  const [subscriptionDuration, setSubscriptionDuration] = useState('1-month')
  const [numUsers, setNumUsers] = useState(1)
  const [additionalStorage, setAdditionalStorage] = useState(0)
  const [termsAgreed, setTermsAgreed] = useState(false)
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [reviewOffer, setReviewOffer] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [emailStatus, setEmailStatus] = useState('')
  const [alwaysOnGPU, setAlwaysOnGPU] = useState(false)
  const [streetAddress1, setStreetAddress1] = useState('');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [newsletterConsent, setNewsletterConsent] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  
  const storageOptions = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
  const [isLoading, setIsLoading] = useState(false) // New state for loading

  const navigate = useNavigate()

  
    const validateForm = () => {
      const errors = {};
      if (!firstName.trim()) errors.firstName = 'First Name is required';
      if (!surname.trim()) errors.surname = 'Surname is required';
      if (!firstName.trim()) errors.firstName = 'First Name is required';
      if (!surname.trim()) errors.surname = 'Surname is required';
      if (!email.trim()) errors.email = 'Email is required';
      if (!companyName.trim()) errors.companyName = 'Company name is required';
      if (!streetAddress1.trim()) errors.streetAddress1 = 'Street Address 1 is required';
      if (!city.trim()) errors.city = 'City is required';
      if (!country.trim()) errors.country = 'Country is required';
      if (!postalCode.trim()) errors.postalCode = 'Postal Code is required';
      if (numUsers < 1) errors.numUsers = 'Number of users must be at least 1';
      if (additionalStorage < 0) errors.additionalStorage = 'Additional storage must be selected';
      if (!termsAgreed) errors.termsAgreed = 'You must agree to the terms';
      
      
      setFormErrors(errors);
      
      console.log(Object.keys(errors));
      return Object.keys(errors).length === 0;
    };
    
    const handleFormSubmit = (e) => {
      e.preventDefault();
      const isValid = validateForm();
    
      if (isValid) {
        setReviewOffer(true);
      } else {
        setErrorMessage('Please fix the errors in the form before proceeding.', errorMessage);
        setSnackbarOpen(true);
      }
    };

    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };

  const handleClose = () => {
    setReviewOffer(false)
    setEmailStatus('') // Reset email status
  }

  const handleProceed = async () => {
    const emailData = new EmailData(
      subscriptionDuration,
      numUsers,
      additionalStorage,
      alwaysOnGPU,
      firstName,
      surname,
      email,
      companyName,
      streetAddress1,
      streetAddress2,
      city,
      country,
      postalCode,
      newsletterConsent
    );

    try {
      setIsLoading(true);
      await sendEmail(emailData, config);
      navigate('/success');
      // Handle success - navigate to success page
    } catch (error) {
      console.log(error);
      setErrorMessage(`Error while sending an email: ${error}`);
      setSnackbarOpen(true);

    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Typography variant='h6' gutterBottom>
          Subscription details
        </Typography>
        <Grid container spacing={2}>
          {/* Subscription Duration */}
          <Grid item xs={12}>
            <FormControl fullWidth error={!!formErrors.subscriptionDuration}>
              <InputLabel>Subscription duration</InputLabel>
              <Select
                value={subscriptionDuration}
                onChange={e => setSubscriptionDuration(e.target.value)}
                label='Subscription duration'
              >
                <MenuItem value='1-month'>1 month</MenuItem>
                <MenuItem value='3-months'>3 months</MenuItem>
                <MenuItem value='6-months'>6 months</MenuItem>
                <MenuItem value='1-year'>1 year</MenuItem>
              </Select>
              <FormHelperText>{formErrors.subscriptionDuration}</FormHelperText>
            </FormControl>
          </Grid>
  
          {/* Number of Users */}
          <Grid item xs={12}>
            <TextField
              label='Number of users'
              fullWidth
              type='number'
              value={numUsers}
              onChange={e => setNumUsers(Math.min(Math.max(parseInt(e.target.value, 10), 1), 10))}
              inputProps={{ min: 1, max: 10 }}
              error={!!formErrors.numUsers}
              helperText={formErrors.numUsers}
            />
          </Grid>
  
          {/* Additional Storage */}
          <Grid item xs={12}>
            <FormControl fullWidth error={!!formErrors.additionalStorage}>
              <InputLabel htmlFor="additional-storage">Additional storage</InputLabel>
              <Select
                value={additionalStorage}
                onChange={e => setAdditionalStorage(e.target.value)}
                label='Additional storage'
                inputProps={{
                  id: 'additional-storage',
                }}
              >
                {storageOptions.map(size => (
                  <MenuItem key={size} value={size}>
                    {size} GB
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{formErrors.additionalStorage}</FormHelperText>
              <Tooltip
                title={
                  <Typography style={{ fontSize: '1rem' }}>
                    Basic environment includes 100 GB of storage. Select more if you require additional space for your projects. 0 GB means you will only have the basic 100 GB of storage.
                  </Typography>
                }
                placement="top"
                arrow
              >
                <HelpOutlineIcon style={{ position: 'absolute', top: '18px', right: '40px', cursor: 'pointer', fontSize: '1.5rem' }} />
              </Tooltip>
            </FormControl>
          </Grid>
  
          {/* Always-On GPU */}
          <Grid item xs={12}>
            <Tooltip
              title={
                <Typography>
                  Choose 'Always-On GPU' for lower latency in starting training processes. Additional costs apply.
                </Typography>
              }
              placement="top"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={alwaysOnGPU}
                    onChange={e => setAlwaysOnGPU(e.target.checked)}
                  />
                }
                label={
                  <Typography>
                    Always-On GPU
                  </Typography>
                }
              />
            </Tooltip>
          </Grid>
        </Grid>
  
        <Typography variant='h6' gutterBottom style={{ paddingTop: '24px' }}>
          Your information
        </Typography>
        <Grid container spacing={2}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              label='First Name'
              required
              fullWidth
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              error={!!formErrors.firstName}
              helperText={formErrors.firstName}
            />
          </Grid>
  
          {/* Surname */}
          <Grid item xs={12} sm={6}>
            <TextField
              label='Surname'
              required
              fullWidth
              value={surname}
              onChange={e => setSurname(e.target.value)}
              error={!!formErrors.surname}
              helperText={formErrors.surname}
            />
          </Grid>
  
          {/* Email */}
          <Grid item xs={12}>
            <TextField
              label='Email'
              required
              fullWidth
              type='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
          </Grid>
  
          {/* Company Name */}
          <Grid item xs={12}>
            <TextField
              label='Company name'
              required
              fullWidth
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
              error={!!formErrors.companyName}
              helperText={formErrors.companyName}
            />
          </Grid>
  
          {/* Address Fields */}
          <Grid item xs={12}>
            <TextField
              label='Street Address 1'
              required
              fullWidth
              value={streetAddress1}
              onChange={e => setStreetAddress1(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Street Address 2'
              fullWidth
              value={streetAddress2}
              onChange={e => setStreetAddress2(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='City'
              required
              fullWidth
              value={city}
              onChange={e => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Postal Code'
              required
              fullWidth
              value={postalCode}
              onChange={e => setPostalCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label='Country'
              required
              fullWidth
              value={country}
              onChange={e => setCountry(e.target.value)}
            />
          </Grid>
  
          {/* Newsletter Consent */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newsletterConsent}
                  onChange={e => setNewsletterConsent(e.target.checked)}
                />
              }
              label="Subscribe to blackshark.ai product newsletter"
            />
          </Grid>
  
          {/* Terms Agreement */}
          <Grid item xs={12} style={{ paddingTop: '12px', paddingBottom: '24px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAgreed}
                  onChange={e => setTermsAgreed(e.target.checked)}
                />
              }
              label={
                <span>
                  I consent to having this website store my submitted information so they can respond to my inquiry. I
                  have read and agreed to the &nbsp;
                  <Link href='https://blackshark.ai/privacy-notice/' target='_blank' rel='noopener noreferrer'>
                    privacy policy.
                  </Link>
                </span>
              }
            />
            {formErrors.termsAgreed && <FormHelperText error>{formErrors.termsAgreed}</FormHelperText>}
          </Grid>
  
          {/* Request Quote Button */}
          <Grid item xs={12}>
            <Button type='submit' variant='contained' color='primary'>
              Request Quote
            </Button>
          </Grid>
        </Grid>
      </form>
  
      {/* Snackbar for displaying error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={<span style={{ fontSize: '18px', color: 'red' }}>{errorMessage}</span>}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />

      {/* Review Information Dialog */}
      <ReviewOfferDialog
        open={reviewOffer}
        onClose={handleClose}
        onProceed={handleProceed}
        subscriptionDuration={subscriptionDuration}
        numUsers={numUsers}
        additionalStorage={additionalStorage}
        alwaysOnGPU={alwaysOnGPU}
        streetAddress1={streetAddress1}
        streetAddress2={streetAddress2}
        city={city}
        country={country}
        postalCode={postalCode}
        firstName={firstName}
        surname={surname}
        email={email}
        companyName={companyName}
        newsletterConsent={newsletterConsent}
        termsAgreed={termsAgreed}
        isLoading={isLoading}
        title="Review Your Information"
      />
      {emailStatus && <p>{emailStatus}</p>}
    </>
  );
  
}

export default FormContainer;
