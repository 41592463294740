import axios from 'axios';

const sendEmail = async (emailData, config) => {
  if (!config || !config.backend) {
    throw new Error('Configuration not loaded');
  }

  try {
    const response = await axios.post(config.backend, emailData);
    return response.data;
  } catch (error) {
    console.error('Failed to send email. Error: ', error);
    throw error;
  }
};

export default sendEmail;