import React from 'react';
import { Typography, Container, Button } from '@mui/material';

const SuccessPage = () => {

const handleRedirect = () => {
  window.location.href = "https://www.blackshark.ai";
};

return (
  <Container>
    <Typography variant="h4" gutterBottom>
      Congratulations!
    </Typography>
    <Typography variant="body1" gutterBottom>
      Your order has been received. Someone will reach out to you soon.
    </Typography>
    <Button variant="contained" color="primary" onClick={handleRedirect}>
      Back to Blackshark.ai
    </Button>
  </Container>
);
}
export default SuccessPage;
